<template>
  <el-card style="min-height: 45rem">
    <el-table :data="list">
      <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
      <el-table-column prop="title" label="标题" show-overflow-tooltip></el-table-column>
      <el-table-column prop="pic" label="封面" show-overflow-tooltip>
        <template #default="s">
          <img v-if="s.row.type === 1" :src="s.row.pic | tomedia" style="height: 3rem;width: 3rem;border: .5rem" alt="">
        </template>
      </el-table-column>
      <el-table-column prop="time" label="时长" show-overflow-tooltip></el-table-column>
      <el-table-column prop="type" label="类型" :formatter="typeFormatter" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="360">
        <template #default="s">
          <el-button @click="$router.push({
          name:'addonsKnowledgeCourseColumnEdit',
          query:{
            id:s.row.id,
            course_id:$route.query.id,
          }
          })" type="primary">编辑
          </el-button>
          <el-button @click="remove(s.row)" type="danger">删除</el-button>
        </template>
        <template #header>
          <el-button @click="$router.push({
          name:'addonsKnowledgeCourseColumnEdit',
          query:{
            course_id:$route.query.id,
          }
          })">添加章节
          </el-button>
          <el-button @click="$router.back()">返回列表</el-button>
        </template>
      </el-table-column>
      <template #append>
        <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                       :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
        </el-pagination>
      </template>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: "course",
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    typeFormatter(item) {
      let str = "";
      switch (parseInt(item.type)) {
        case 1:
          str = "视频";
          break;
        case 2:
          str = "音频";
          break;
        case 3:
          str = "图文";
          break;
      }
      return str;
    },
    pageChange(e) {
      this.page = e;
      this.load();
    },
    load() {
      this.$api.addons.knowledge.courseColumn({
        page: this.page,
        course_id:parseInt(this.$route.query.id),
      }).then(res => {
        this.list = res.list;
        this.total = res.total;
      })
    },
    remove({id}) {
      this.$api.addons.knowledge.courseColumnDel({id}).then(() => {
        this.$message.success("操作成功")
        this.load();
      })
    },
  },
}
</script>

<style scoped>

</style>